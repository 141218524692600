import React, { useState } from 'react'
import { InputSso, TitleFont20WithoutMarginBlueSso, ButtonSsoHover, IconOnaos, UnderTextSso } from 'onaosreact'
import { apiLogin } from '../../api/api'
import { useNavigate } from 'react-router-dom'

const TAB = [
    {
        title: 'E-mail',
        placeholder: 'Entrer votre mail',
        onChange: '',
        id: 'email',
        errors: {
            is: false,
            msg: 'Veuillez entrer votre email',
        },
    },
    {
        title: 'Mot de passe',
        placeholder: 'Entrer votre mot de passe',
        onChange: '',
        password: true,
        id: 'password',
        errors: {
            is: false,
            msg: 'Veuillez entrer votre mot de passe',
        },
    },
]

export const Connexion = (props) => {
    const [datas, setDatas] = useState({ email: '', password: '' })
    const [, updateState] = useState()
    const navigate = useNavigate()

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
    }

    const iconStyle = {
        width: '130px',
        cursor: '',
    }

    const divPosition = {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    }

    const saveName = (id, value) => {
        const tmp = { ...datas }
        tmp[id] = value
        setDatas(tmp)
    }

    const doLog = (user, pass) => {
        apiLogin(user, pass, props.service).then((data) => {
            if (data.status === 200) {
                if (props.service !== null && props.service !== undefined && data.url !== undefined) {
                    setTimeout(() => window.open(data.url, '_self'), 200)
                }
            } else {
                for (let index = 0; index < TAB.length; index++) {
                    TAB[index].errors.is = true
                    TAB[index].errors.msg = 'E-mail ou mot de passe incorrects'
                }
                updateState({})
            }
        })
    }

    const checkDatas = () => {
        let isError = false

        for (let index = 0; index < TAB.length; index++) {
            TAB[index].errors.is = datas[TAB[index].id] === '' ? true : false

            if (TAB[index].errors.is === true) {
                isError = true
                continue
            }

            isError = !isError && TAB[index].errors.is ? true : isError
        }
        if (isError) {
            updateState({})
        } else {
            doLog(datas.email, datas.password)
        }
    }

    return (
        <div style={containerStyle}>
            <IconOnaos style={iconStyle} />
            <div style={divPosition}>
                <TitleFont20WithoutMarginBlueSso text='Connexion' />
                {TAB.map((data, k) => (
                    <InputSso
                        key={'inputssso_' + k}
                        title={data.title}
                        password={data.password}
                        placeholder={data.placeholder !== undefined ? data.placeholder : datas[data.id]}
                        onChange={data.onChange !== undefined ? (e) => saveName(data.id, e) : ''}
                        data={data}
                        errors={data.errors}
                        onValidateEnter={() => checkDatas()}
                    />
                ))}
            </div>
            <div>
                <ButtonSsoHover text='Valider' onClick={() => checkDatas()} />
                <UnderTextSso undertext={'Mot de passe oublié ?'} onClick={() => navigate('/connexion/oubli')} />
            </div>
        </div>
    )
}
