import React, { useState } from 'react'
import { InputSso, TitleFont20WithoutMarginBlueSso, ButtonSsoHover, IconOnaos, TextCenterBlue, UnderTextSso } from 'onaosreact'
import { getAPI } from '../../api/api'
import { useNavigate } from 'react-router-dom'

const TAB = [
    {
        title: 'E-mail',
        placeholder: 'Entrer votre mail',
        onChange: '',
        id: 'email',
        errors: {
            is: false,
            msg: 'Veuillez entrer votre email',
        },
    },
]

export const ForgetPassword = (props) => {
    const [datas, setDatas] = useState({ email: '' })
    const [, updateState] = useState()
    const [showMsg, setMsg] = useState(false)

    const navigate = useNavigate()

    const containerStyle = {
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
    }

    const iconStyle = {
        width: '130px',
        cursor: '',
    }

    const textStyle = {
        width: '100%',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        wordBreak: 'break-word',
    }

    const inputStyle = {
        width: '100%',
    }

    const buttonStyle = {
        width: '100%',
    }

    const confirmationStyle = {
        color: 'rgb(103, 153, 30)',
        fontWeight: 'bold',
    }

    const underTextStyle = {
        position: 'absolute',
        bottom: '0',
    }

    const saveName = (id, value) => {
        const tmp = { ...datas }
        tmp[id] = value
        setDatas(tmp)
    }

    const checkDatas = () => {
        let isError = false

        for (let index = 0; index < TAB.length; index++) {
            TAB[index].errors.is = datas[TAB[index].id] === '' ? true : false

            if (TAB[index].errors.is === true) {
                isError = true
            }

            isError = !isError && TAB[index].errors.is ? true : isError

            if (isError) updateState({})
            else {
                getAPI('/forgetpassword/' + datas.email, '').then((data) => {
                    if (data.ok) {
                        setMsg(true)
                    } else {
                        updateState({})
                    }
                })
            }
        }
    }

    return (
        <div style={containerStyle}>
            <IconOnaos style={iconStyle} />
            <TitleFont20WithoutMarginBlueSso text='Vous avez oublié votre mot de passe ?' />
            <span style={textStyle}>
                Pas d'inquiétude ! Pour récupérer votre mot de passe, veuillez saisir l'adresse e-mail utilisée pour votre inscription, nous vous
                enverrons un e-mail vous permettant de réinitialiser votre mot de passe.
            </span>
            {TAB.map((data, k) => (
                <InputSso
                    style={inputStyle}
                    key={'inputssso_' + k}
                    title={data.title}
                    password={data.password}
                    placeholder={data.placeholder !== undefined ? data.placeholder : datas[data.id]}
                    onChange={data.onChange !== undefined ? (e) => saveName(data.id, e) : ''}
                    data={data}
                    errors={data.errors}
                />
            ))}
            <ButtonSsoHover style={buttonStyle} text='Réinitialiser le mot de passe' onClick={() => checkDatas()} />
            {showMsg && <TextCenterBlue text="L'email de récupération a été envoyé" style={confirmationStyle} />}
            <UnderTextSso
                setStyle={underTextStyle}
                undertext="Pour revenir à la page d'accueil, cliquer ici"
                onClick={() => navigate('/connexion')} /* TODO : use a var to redirect with service where reset was ask */
            />
        </div>
    )
}
